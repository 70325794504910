import {Component} from "../../sedestral-interface-component/interface/component/Component";
import {ISelectBoxEntry} from "./types/ISelectBoxEntry";
import * as s from "./selectbox.scss";
import {VisualSelectBoxTooltipComponent} from "./tooltip/VisualSelectBoxTooltipComponent";
import {IVisualSelectBox} from "./types/IVisualSelectBox";
import {Resources} from "../../../resources/Resources";

export class VisualSelectBoxComponent extends Component {

    public selectIcon: Component;
    public settings: IVisualSelectBox;
    public selectedContainer: Component;
    public pickerComponent: VisualSelectBoxTooltipComponent;
    private selectedValue: string | number | string[];


    constructor(settings: IVisualSelectBox) {
        super();
        this.settings = settings;
        this.selectedValue = !this.settings.selectedValue && this.settings.multiple ? [] : this.settings.selectedValue;
        //language=HTML
        this.template = `
            <div ${this.settings.maxHeight ? 'style="height: calc(100% - 8px);"' : ""} data-selectbox="true"
                 class="${s.globalSelectbox}">
                <div class="${s.selectIcon}"></div>
                <div style="font-size: ${this.settings?.fontSize}px;" class="${s.selectButton}"></div>
            </div>
        `;
    }

    commit() {
        this.selectIcon = this.el(s.selectIcon);
        this.selectedContainer = this.el(s.selectButton);
        this.selectedContainer.onClick(() => this.renderPicker());

        this.onRemove(() => {
            if (this.pickerComponent != undefined && !this.pickerComponent.isNull()) {
                this.pickerComponent.remove();
            }
        });


        this.renderSelection();
        super.commit();
    }

    select(value: any) {
        if (this.settings.entries.length > 0) {
            let entry = this.settings.entries.filter(entry => entry.value == value)[0];
            if (entry != undefined) {
                if (!this.settings.multiple) {
                    if (this.settings.selectedValueNullable && this.selectedValue == entry.value) {
                        this.selectedValue = undefined;
                    } else {
                        this.selectedValue = entry.value;
                    }
                } else {
                    let values = (this.selectedValue as string[]);
                    let v = entry.value as string;
                    if (values.indexOf(v) >= 0) {
                        values.splice(values.indexOf(v), 1);
                    } else {
                        (this.selectedValue as string[]).push("" + entry.value);
                    }
                }

                this.renderSelection();
            }
        }
    }

    renderSelection() {
        if (!this.settings.multiple) {
            this.selectedContainer.setHtml(this.settings.previewIsValue ? this.getSelection().value : this.getSelection().label);
        } else {
            if (this.getSelections().length > 0) {
                this.selectedContainer.setHtml(this.getSelections().map(value => value.label).join(", "));
            } else {
                this.selectedContainer.setHtml(this.settings.noneText ? this.settings.noneText : Resources.t("words.none"));
            }
        }
    }

    renderPicker() {
        if (this.pickerComponent != undefined && !this.pickerComponent.isNull()) {
            this.pickerComponent.dispose();
        } else {
            this.pickerComponent = new VisualSelectBoxTooltipComponent(this.selectedContainer, this.settings, this.selectedValue);
            this.pickerComponent.screen(false);
            this.pickerComponent.onOutsideClick(() => this.pickerComponent.dispose());
            this.pickerComponent.create();
            this.pickerComponent.onSelect = (entry) => {
                if (this.beforeSelect(entry)) {
                    this.select("" + entry.value);
                    this.onSelect(entry);
                }
            };
        }
    }

    getSelection(): ISelectBoxEntry {
        if (this.settings.multiple) {
            let selectedValues = this.selectedValue as string[];
            if (this.settings.entries.length == 0 || ((!selectedValues || selectedValues.length == 0) && this.settings.selectedValueNullable != undefined && this.settings.selectedValueNullable == true)) {
                // @ts-ignore
                return [{
                    value: undefined,
                    label: this.settings.noneText ? this.settings.noneText : Resources.t("words.none")
                }]
            } else {
                if (!selectedValues) { // @ts-ignore
                    return [this.settings.entries[0]];
                } else {
                    // @ts-ignore
                    return selectedValues.map(value => {
                        return this.settings.entries.find(entry => entry.value == value)
                    });
                }
            }

        } else {
            let selectedValue = this.selectedValue as string;
            if (this.settings.entries.length == 0 || (!selectedValue && this.settings.selectedValueNullable != undefined && this.settings.selectedValueNullable == true)) {
                return {
                    value: undefined,
                    label: this.settings.noneText ? this.settings.noneText : Resources.t("words.none")
                }
            } else {
                if (!selectedValue) {
                    return this.settings.entries[0];
                } else {
                    return this.settings.entries.find(value => value.value == selectedValue) ?? this.settings.entries[0];
                }
            }
        }
    }

    getSelections(): ISelectBoxEntry[] {
        return this.getSelection() as unknown as ISelectBoxEntry[];
    }

    classDisable() {
        this.addClass(s.disabled);
    }

    /**
     * override
     *
     */

    beforeSelect(entry: ISelectBoxEntry): boolean {
        return true;
    }

    onSelect(entry: ISelectBoxEntry) {

    }
}