export class config {
    public static websiteUrl: string = process.env.WEBSITE_URL;
    public static domain: string = process.env.APP_URL ?? window.location.origin;
    public static version: string = process.env.APP_VERSION;
    public static environment: string = process.env.APP_ENVIRONMENT;
    public static isDev: boolean = process.env.APP_ENVIRONMENT === "dev";
    public static isProd: boolean = config.environment == "prod";
    public static httpPanelUrl: string = process.env.SPRING_HTTP_PANEL;
    public static httpCommunityUrl: string = process.env.SPRING_HTTP_COMMUNITY;
    public static websocketPanelUrl: string = process.env.SPRING_WS_PANEL;
    public static websocketCommunityUrl: string = process.env.SPRING_WS_COMMUNITY;
    public static editorCollaborationUrl: string = process.env.EDITOR_COLLABORATION_WS;
    public static rumEnabled: boolean = process.env.RUM_ENABLED === "true";
    public static helpCenterSubDomain: string = process.env.HELPCENTER_SUBDOMAIN;
    public static blogSubDomain: string = process.env.BLOG_SUBDOMAIN;

    public static product: string;

    public static tenorKey: string = "1HFPKNKBSCVT";
    public static tenorHost: string = "https://g.tenor.com/v1/";
    public static tenorMediaHost: string = "https://media.tenor.com/";
    public static domainParent: string = "sedestral.com";

    public static livechatId: string = process.env.LIVECHAT_ID;
    public static hotjarId: string = process.env.HOTJAR_ID;
    public static hotjarPanelId: string = process.env.HOTJAR_PANEL_ID;

    public static livechatStyleUrl: () => string = () => process.env.LIVECHAT_URL + "/livechat.css?";
    public static livechatJsUrl: () => string = () => process.env.LIVECHAT_URL + "/livechat.js?";

    static import(product: string) {
        this.product = product;
    }
}

export class firebaseConfig {
    public static config = {
        apiKey: "AIzaSyCc15ypn8pO3y-ypDbSegb_Bk5bPSIAu9U",
        authDomain: "sedestral-86c7f.firebaseapp.com",
        projectId: "sedestral-86c7f",
        storageBucket: "sedestral-86c7f.appspot.com",
        messagingSenderId: "627959172196",
        appId: "1:627959172196:web:e093c1d3bdea0e96cf6371"
    };
    public static vapidKey = "BBmq-zPIaRUHlvMcz-hSyQ_k04tfvM8CUshZDQ012j4j28SmvVoaxD4n7qYmUnMaH98zOnR-DDRnIx6UElUrnUc";
}